import { Modal, Flex, Text, StyledCheckbox, Button} from 'components'
import React, {useState, useEffect, useCallback} from 'react'

const LinkGroupModal = ({groups, toggleGroup, selectedUser, value, t, hide}) => {
  const [selectedGroups, setSelectedGroups] = useState([])

  useEffect(() => {
    if (selectedUser.groups != null
      && selectedGroups.length != selectedUser.groups.length) {
      setSelectedGroups(selectedUser.groups)
    }  

    if (selectedGroups == null && selectedUser != null
      && selectedUser.groups != null
      && selectedUser.groups.length > 0)
    {
      setSelectedGroups(selectedUser.groups)
    }

  }, [selectedUser, groups, selectedUser.groups])

  return (
    <Modal title={t('Vælg grupper')} hide={hide}>
      <Flex flexDirection="column" p={3}>
        {
            groups.map((group)=> {
              const isGroupInArray = selectedGroups.some(g => g.id === group.id);

              return <Flex justifyContent="space-between" width={1}>
              <Text mb={2}>{group.title}</Text>
              <StyledCheckbox 
                checked={isGroupInArray}
                onChange={({target: {checked}}) => {
                  if (checked) {             
                    var newSelectedGroups = selectedGroups.filter(g => g.id != group.id);
                    newSelectedGroups.push(group);
                    setSelectedGroups(newSelectedGroups)
                  } else {
                    var newSelectedGroups = selectedGroups.filter(g => g.id != group.id);
                    setSelectedGroups(newSelectedGroups)
                  }
                
                }} />
            </Flex>
            })
        }
      
        <Button type='button' onClick={()=> {
            toggleGroup(selectedGroups, selectedUser)
            hide()
            setSelectedGroups(null)
        }}>{t("Vælg grupper")}</Button>
      </Flex>
    </Modal>
      
)
}

export default LinkGroupModal
